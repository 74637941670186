/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { seoConData } from "./../components/SearchResults/SearchFilters/filterOptions"
import { useGlobalStore } from "./../stores/globalStore"
import parse from "html-react-parser"
import QueryString from "qs"
import _slugify from "slugify"

export const scrollToElement = (element, offset = 1, smooth = true) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: smooth ? "smooth" : "auto",
    })
}
export const hasMyaccount = (process.env.GATSBY_MYACCOUNT || null) === '1' ? true : false;

export const scrollToElementForm = (ref, smooth = true) => {
  window.scrollTo({
    top: ref.current.offsetTop - 100,
    behavior: smooth ? "smooth" : "auto",
  });
}

export const scrollToID = (id, offset = 1) => {
  const element = document.getElementById(id)

  if (element) {
    scrollToElement(element, offset)
  }
}

export const getMenuLink = (menu) => {
  let link = {}

  if (menu) {
    const { slug, custom_link, external_link, strapi_parent, link_type } = menu

    if (link_type === "popup") {
      return null
    }

    const formattedCustomLink = custom_link?.replace(/ /g, "")

    if (formattedCustomLink) {
      if (formattedCustomLink.includes("http") || external_link) {
        link = {
          href: formattedCustomLink,
          target: "_blank",
        }
      } else {
        link = {
          to: formattedCustomLink,
        }
      }
    } else if (slug) {
      // TODO: add recursive function to get parent slugs
      if (strapi_parent) {
        link = {
          to: `/${strapi_parent.slug}/${slug}/`,
        }
      } else {
        link = {
          to: `/${slug}/`,
        }
      }
    }
  }

  return link
}

export const parseHTML = (htmlString) => {
  if (typeof htmlString === "string" && htmlString?.length > 0) {
    return parse(htmlString)
  }

  return null
}

export const stripHTML = (htmlString) => {
  if (typeof htmlString === "string" && htmlString?.length > 0) {
    return htmlString.replace(/(<([^>]+)>)/gi, "")
  }

  return null
}

export const getIconColor = (color, defaultColor) => {
  let stroke = defaultColor || "#07234B"

  if (color?.[0] === "#") return color

  switch (color) {
    case "white":
      stroke = "#fff"
      break
    case "blue":
      stroke = "#07234B"
      break
    case "black":
      stroke = "#35373C"
      break
    case "orange":
      stroke = "#EE7133"
      break
    default:
      stroke = defaultColor || "#07234B"
      break
  }

  return stroke
}
export const addRollWhatsapp = () => {
  if(typeof window !== "undefined"){
    try {
      window.__adroll.record_user({"adroll_segments": "27874fb2"});
  } catch(err) {
  }
}
}
export const getWhatsAppURL = (number, message, email, resp_phone, resp_name) => {
  if (!number) return null
  // remove everything except numbers
 
  const formatedNumber = number?.replace(/\D/g, "")
  const formatedrepoNumber = resp_phone?.replace(/\D/g, "")
  const { leadSource } = useGlobalStore()
  let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
    const query = QueryString.stringify(
      {
        phone: formatedNumber,
        email: email,
        resp_phone: formatedrepoNumber,
        text: message,
        resp_name: resp_name,
        utm_source: seoSourceName && seoSourceName?.length > 0 && seoSourceName[0]?.name
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
  
  

  return `https://whatsapp-yecmsd26ra-uc.a.run.app?${query}`
}


export const getWhatsBaseURL = (number, message) => {
  if (!number) return null
  // remove everything except numbers
  const { leadSource } = useGlobalStore()
  let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
  const formatedNumber = number?.replace(/\D/g, "")
 
    const query = QueryString.stringify(
      {
        phone: formatedNumber,
        text: message,
        utm_source: seoSourceName && seoSourceName?.length > 0 && seoSourceName[0]?.name

      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
  
  

  return `https://api.whatsapp.com/send?${query}`
}

export const getWhatsRedirectURL = (number, message) => {
  if (!number) return null
  // remove everything except numbers
  const { leadSource } = useGlobalStore()
  let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
  const formatedNumber = number?.replace(/\D/g, "")
 
    const query = QueryString.stringify(
      {
        phone: formatedNumber,
        text: message,
        utm_source: seoSourceName && seoSourceName?.length > 0 && seoSourceName[0]?.name

      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
  
  

  return `https://redirectclienttocompany-yecmsd26ra-uc.a.run.app/send?${query}`
}

export const formatNumber = (number) => {
  if (number || number === 0) {
    return Number(number.toString()?.replace(/[^\d.]/g, "")).toLocaleString(
      "en-US"
    )
  }

  return null
}

export const filterNumber = (str) => {
  if (!str) return 0
  const myStr = str.toString()
  return parseInt(myStr.replace(/[^\d.]/g, ""), 10)
}

export const currencyFormat = (num, currency = "AED ", withSpace = false) => {
  const filterNum = filterNumber(num)
  if (!filterNum) {
    return `${currency} 0`
  }
  if (withSpace) {
    return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }
  return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
}

export const calculateMonthlyPayment = ({
  price,
  downPaymentPercentage,
  interestRateYearly,
  loanPeriodYearly,
}) => {
  const downPayment =
    parseInt(price, 10) * (parseInt(downPaymentPercentage, 10) / 100)
  const loanAmount = parseInt(price, 10) - downPayment
  const monthlyInterestRate = parseFloat(interestRateYearly) / (12 * 100)
  const loanPeriodInMonths = parseInt(loanPeriodYearly, 10) * 12

  if (loanAmount <= 0 || loanPeriodInMonths <= 0) {
    return 0
  }

  const monthlyPayment =
    (loanAmount * monthlyInterestRate) /
    (1 - (1 + monthlyInterestRate) ** -loanPeriodInMonths)

  if (
    Number.isNaN(monthlyPayment) ||
    monthlyPayment === Infinity ||
    monthlyPayment === -Infinity ||
    monthlyPayment < 0
  ) {
    return 0
  }

  return monthlyPayment
}

// TODO: make this recursive
export const getBreadcrumbUrls = (menuData) => {
  const urls = []
  const current = menuData
  if (current?.strapi_parent?.strapi_parent) {
    urls.push({
      label: current.strapi_parent.strapi_parent.title,
      url: `/${current.strapi_parent.strapi_parent.slug}/`,
    })
    urls.push({
      label: current.strapi_parent.title,
      url: `/${current.strapi_parent.strapi_parent.slug}/${current.strapi_parent.slug}/`,
    })
    urls.push({
      label: current.title,
      url: `/${current.strapi_parent.strapi_parent.slug}/${current.strapi_parent.slug}/${current.slug}/`,
    })
  } else if (current?.strapi_parent) {
    urls.push({
      label: current.strapi_parent.title,
      url: `/${current.strapi_parent.slug}/`,
    })
    urls.push({
      label: current.title,
      url: `/${current.strapi_parent.slug}/${current.slug}/`,
    })
  } else {
    urls.push({
      label: current?.title,
      url: `/${current?.slug}/`,
    })
  }
  return urls
}

export const trackShare = (event, shareURL) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "Share - social",
    formType: `${event} - ${shareURL}`,
    formId: "Share",
    formName: "Share",
    formLabel: "Share",
  })
}

export const abbreviateNumber = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 1,
  })
  const newValue = formatter.format(value).toLocaleLowerCase()
  return newValue
}

export const slugToTitle = (
  slug,
  { capitalize } = {
    capitalize: true,
  }
) => {
  if (!slug || typeof slug !== "string") return null

  const title = slug
    .split("-")
    .map((word) => {
      if (capitalize) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word
    })
    .join(" ")
  return title
}

export const addBitrixData = (
  data,
  { leadType, searchType } = {
    leadType: "valuation",
    searchType: "vendor",
  }
) => {
  const enabled = process.env.GATSBY_BITRIX_ENABLED === "true"

  let frTelephone = ""
  if (data.telephone) {
    frTelephone = data.telephone
    if (!frTelephone.startsWith("+")) {
      frTelephone = `+${frTelephone}`
    }
  }

  if (enabled) {
    const bitrixData = {
      ...data,
      telephone: frTelephone,
      bitrix24_data: {
        ...data,
        telephone: frTelephone,
        lead_type: leadType,
        search_type: searchType,
      },
    }
    return bitrixData
  }

  return data
}

export const sort = (a, b, sortType = "asc") => {
  if (a === null || a === undefined) {
    return 1
  }
  if (b === null || b === undefined) {
    return -1
  }
  if (sortType === "asc") {
    return a - b
  }
  return b - a
}

export const slugify = (text) => {
  if (!text) return ""

  let toSlug = text

  if (typeof text !== "string") {
    toSlug = text.toString()
  }

  if (typeof toSlug !== "string") return ""
  toSlug = toSlug.replace(/&/g, '');

  return _slugify(toSlug, { lower: true, trim: true, strict: true })
}

export const slugifynew = (text) => {
  if (!text) return ""

  let toSlug = text

  if (typeof text !== "string") {
    toSlug = text.toString()
  }

  if (typeof toSlug !== "string") return ""
  toSlug = toSlug.replace(/&/g, '');

  return _slugify(toSlug, { lower: true, trim: true, strict: true, remove: /[*+~.()'"!:@]/g })
}
export const getOrderedImages = (offplan) => {
  // Initialize an empty array to store the ordered images
  let imagesArray = [];

  if (offplan.tile_image) {
    // If tile_image exists, check media_images
    if (offplan.media_images?.length > 0) {
      imagesArray = [offplan.tile_image, ...offplan.media_images];
    } else if (offplan.images?.length > 0) {
      // If media_images is not available, use images array with tile_image first
      imagesArray = [offplan.tile_image, ...offplan.images];
    } else {
      // If neither media_images nor images are available, only use tile_image
      imagesArray = [offplan.tile_image];
    }
  } else if (offplan.media_images?.length > 0) {
    // If tile_image is not available, use media_images if it exists
    imagesArray = [...offplan.media_images];
  } else if (offplan.images?.length > 0) {
    // If neither tile_image nor media_images are available, use images
    imagesArray = [...offplan.images];
  }

  return imagesArray;
};
export function gtagReportConversion(url) {
  const callback = () => {
    if (typeof url !== "undefined") {
      // window.location = url
    }
  }

  typeof window !== "undefined" &&
    window.dataLayer?.push("event", "conversion", {
      send_to: "G-VTTZT0CG7Y/dvs1CK7Pi-gYENiv0uMC",
      event_callback: callback,
      value: url,
    })
    if(typeof window !== "undefined"){
    try {
      window.__adroll.record_user({"adroll_segments": "81933a6c"});
  } catch(err) {
      console.error('Error recording AdRoll user:', err);
  }
}
  return false
}

export const capitalize = (input) => {
  let text = input

  if (typeof text !== "string") {
    try {
      text = String(text)
    } catch (e) {
      return ""
    }
  }

  if (text.length === 0) {
    return ""
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const trim = (input) => {
  let text = input

  if (typeof text !== "string") {
    try {
      text = String(text)
    } catch (e) {
      return ""
    }
  }

  if (text.length === 0) {
    return ""
  }

  return text.trim()
}

export function spliceString(str, index, count, add) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index
    if (index < 0) {
      index = 0
    }
  }

  return str.slice(0, index) + (add || "") + str.slice(index + count)
}

export const countryList = [
  {
    name: "Afghanistan",
    id: "240954",
  },
  {
    name: "Albania",
    id: "240955",
  },
  {
    name: "Algeria",
    id: "240956",
  },
  {
    name: "Andorra",
    id: "240957",
  },
  {
    name: "Angola",
    id: "240958",
  },
  {
    name: "Antigua and Barbuda",
    id: "240959",
  },
  {
    name: "Argentina",
    id: "240960",
  },
  {
    name: "Armenia",
    id: "240961",
  },
  {
    name: "Australia",
    id: "240962",
  },
  {
    name: "Austria",
    id: "240963",
  },
  {
    name: "Azerbaijan",
    id: "240964",
  },
  {
    name: "Bahamas",
    id: "240965",
  },
  {
    name: "Bahrain",
    id: "240966",
  },
  {
    name: "Bangladesh",
    id: "240967",
  },
  {
    name: "Barbados",
    id: "240968",
  },
  {
    name: "Belarus",
    id: "240969",
  },
  {
    name: "Belgium",
    id: "240970",
  },
  {
    name: "Belize",
    id: "240971",
  },
  {
    name: "Benin",
    id: "240972",
  },
  {
    name: "Bhutan",
    id: "240973",
  },
  {
    name: "Bolivia",
    id: "240974",
  },
  {
    name: "Bosnia and Herzegovina",
    id: "240975",
  },
  {
    name: "Botswana",
    id: "240976",
  },
  {
    name: "Brazil",
    id: "240977",
  },
  {
    name: "Brunei",
    id: "240978",
  },
  {
    name: "Bulgaria",
    id: "240979",
  },
  {
    name: "Burkina Faso",
    id: "240980",
  },
  {
    name: "Burundi",
    id: "240981",
  },
  {
    name: "Côte d'Ivoire",
    id: "240982",
  },
  {
    name: "Cabo Verde",
    id: "240983",
  },
  {
    name: "Cambodia",
    id: "240984",
  },
  {
    name: "Cameroon",
    id: "240985",
  },
  {
    name: "Canada",
    id: "240986",
  },
  {
    name: "Central African Republic",
    id: "240987",
  },
  {
    name: "Chad",
    id: "240988",
  },
  {
    name: "Chile",
    id: "240989",
  },
  {
    name: "China",
    id: "240990",
  },
  {
    name: "Colombia",
    id: "240991",
  },
  {
    name: "Comoros",
    id: "240992",
  },
  {
    name: "Congo (Congo-Brazzaville)",
    id: "240993",
  },
  {
    name: "Costa Rica",
    id: "240994",
  },
  {
    name: "Croatia",
    id: "240995",
  },
  {
    name: "Cuba",
    id: "240996",
  },
  {
    name: "Cyprus",
    id: "240997",
  },
  {
    name: "Czechia (Czech Republic)",
    id: "240998",
  },
  {
    name: "Democratic Republic of the Congo",
    id: "240999",
  },
  {
    name: "Denmark",
    id: "241000",
  },
  {
    name: "Djibouti",
    id: "241001",
  },
  {
    name: "Dominica",
    id: "241002",
  },
  {
    name: "Dominican Republic",
    id: "241003",
  },
  {
    name: "Ecuador",
    id: "241004",
  },
  {
    name: "Egypt",
    id: "241005",
  },
  {
    name: "El Salvador",
    id: "241006",
  },
  {
    name: "Equatorial Guinea",
    id: "241007",
  },
  {
    name: "Eritrea",
    id: "241008",
  },
  {
    name: "Estonia",
    id: "241009",
  },
  {
    name: 'Eswatini (fmr. "Swaziland")',
    id: "241010",
  },
  {
    name: "Ethiopia",
    id: "241011",
  },
  {
    name: "Fiji",
    id: "241012",
  },
  {
    name: "Finland",
    id: "241013",
  },
  {
    name: "France",
    id: "241014",
  },
  {
    name: "Gabon",
    id: "241015",
  },
  {
    name: "Gambia",
    id: "241016",
  },
  {
    name: "Georgia",
    id: "241017",
  },
  {
    name: "Germany",
    id: "241018",
  },
  {
    name: "Ghana",
    id: "241019",
  },
  {
    name: "Greece",
    id: "241020",
  },
  {
    name: "Grenada",
    id: "241021",
  },
  {
    name: "Guatemala",
    id: "241022",
  },
  {
    name: "Guinea",
    id: "241023",
  },
  {
    name: "Guinea-Bissau",
    id: "241024",
  },
  {
    name: "Guyana",
    id: "241025",
  },
  {
    name: "Haiti",
    id: "241026",
  },
  {
    name: "Holy See",
    id: "241027",
  },
  {
    name: "Honduras",
    id: "241028",
  },
  {
    name: "Hungary",
    id: "241029",
  },
  {
    name: "Iceland",
    id: "241030",
  },
  {
    name: "India",
    id: "241031",
  },
  {
    name: "Indonesia",
    id: "241032",
  },
  {
    name: "Iran",
    id: "241033",
  },
  {
    name: "Iraq",
    id: "241034",
  },
  {
    name: "Ireland",
    id: "241035",
  },
  {
    name: "Israel",
    id: "241036",
  },
  {
    name: "Italy",
    id: "241037",
  },
  {
    name: "Jamaica",
    id: "241038",
  },
  {
    name: "Japan",
    id: "241039",
  },
  {
    name: "Jordan",
    id: "241040",
  },
  {
    name: "Kazakhstan",
    id: "241041",
  },
  {
    name: "Kenya",
    id: "241042",
  },
  {
    name: "Kiribati",
    id: "241043",
  },
  {
    name: "Kuwait",
    id: "241044",
  },
  {
    name: "Kyrgyzstan",
    id: "241045",
  },
  {
    name: "Laos",
    id: "241046",
  },
  {
    name: "Latvia",
    id: "241047",
  },
  {
    name: "Lebanon",
    id: "241048",
  },
  {
    name: "Lesotho",
    id: "241049",
  },
  {
    name: "Liberia",
    id: "241050",
  },
  {
    name: "Libya",
    id: "241051",
  },
  {
    name: "Liechtenstein",
    id: "241052",
  },
  {
    name: "Lithuania",
    id: "241053",
  },
  {
    name: "Luxembourg",
    id: "241054",
  },
  {
    name: "Madagascar",
    id: "241055",
  },
  {
    name: "Malawi",
    id: "241056",
  },
  {
    name: "Malaysia",
    id: "241057",
  },
  {
    name: "Maldives",
    id: "241058",
  },
  {
    name: "Mali",
    id: "241059",
  },
  {
    name: "Malta",
    id: "241060",
  },
  {
    name: "Marshall Islands",
    id: "241061",
  },
  {
    name: "Mauritania",
    id: "241062",
  },
  {
    name: "Mauritius",
    id: "241063",
  },
  {
    name: "Mexico",
    id: "241064",
  },
  {
    name: "Micronesia",
    id: "241065",
  },
  {
    name: "Moldova",
    id: "241066",
  },
  {
    name: "Monaco",
    id: "241067",
  },
  {
    name: "Mongolia",
    id: "241068",
  },
  {
    name: "Montenegro",
    id: "241069",
  },
  {
    name: "Morocco",
    id: "241070",
  },
  {
    name: "Mozambique",
    id: "241071",
  },
  {
    name: "Myanmar (formerly Burma)",
    id: "241072",
  },
  {
    name: "Namibia",
    id: "241073",
  },
  {
    name: "Nauru",
    id: "241074",
  },
  {
    name: "Nepal",
    id: "241075",
  },
  {
    name: "Netherlands",
    id: "241076",
  },
  {
    name: "New Zealand",
    id: "241077",
  },
  {
    name: "Nicaragua",
    id: "241078",
  },
  {
    name: "Niger",
    id: "241079",
  },
  {
    name: "Nigeria",
    id: "241080",
  },
  {
    name: "North Korea",
    id: "241081",
  },
  {
    name: "North Macedonia",
    id: "241082",
  },
  {
    name: "Norway",
    id: "241083",
  },
  {
    name: "Oman",
    id: "241084",
  },
  {
    name: "Pakistan",
    id: "241085",
  },
  {
    name: "Palau",
    id: "241086",
  },
  {
    name: "Palestine State",
    id: "241087",
  },
  {
    name: "Panama",
    id: "241088",
  },
  {
    name: "Papua New Guinea",
    id: "241089",
  },
  {
    name: "Paraguay",
    id: "241090",
  },
  {
    name: "Peru",
    id: "241091",
  },
  {
    name: "Philippines",
    id: "241092",
  },
  {
    name: "Poland",
    id: "241093",
  },
  {
    name: "Portugal",
    id: "241094",
  },
  {
    name: "Qatar",
    id: "241095",
  },
  {
    name: "Romania",
    id: "241096",
  },
  {
    name: "Russia",
    id: "241097",
  },
  {
    name: "Rwanda",
    id: "241098",
  },
  {
    name: "Saint Kitts and Nevis",
    id: "241099",
  },
  {
    name: "Saint Lucia",
    id: "241100",
  },
  {
    name: "Saint Vincent and the Grenadines",
    id: "241101",
  },
  {
    name: "Samoa",
    id: "241102",
  },
  {
    name: "San Marino",
    id: "241103",
  },
  {
    name: "Sao Tome and Principe",
    id: "241104",
  },
  {
    name: "Saudi Arabia",
    id: "241105",
  },
  {
    name: "Senegal",
    id: "241106",
  },
  {
    name: "Serbia",
    id: "241107",
  },
  {
    name: "Seychelles",
    id: "241108",
  },
  {
    name: "Sierra Leone",
    id: "241109",
  },
  {
    name: "Singapore",
    id: "241110",
  },
  {
    name: "Slovakia",
    id: "241111",
  },
  {
    name: "Slovenia",
    id: "241112",
  },
  {
    name: "Solomon Islands",
    id: "241113",
  },
  {
    name: "Somalia",
    id: "241114",
  },
  {
    name: "South Africa",
    id: "241115",
  },
  {
    name: "South Korea",
    id: "241116",
  },
  {
    name: "South Sudan",
    id: "241117",
  },
  {
    name: "Spain",
    id: "241118",
  },
  {
    name: "Sri Lanka",
    id: "241119",
  },
  {
    name: "Sudan",
    id: "241120",
  },
  {
    name: "Suriname",
    id: "241121",
  },
  {
    name: "Sweden",
    id: "241122",
  },
  {
    name: "Switzerland",
    id: "241123",
  },
  {
    name: "Syria",
    id: "241124",
  },
  {
    name: "Tajikistan",
    id: "241125",
  },
  {
    name: "Tanzania",
    id: "241126",
  },
  {
    name: "Thailand",
    id: "241127",
  },
  {
    name: "Timor-Leste",
    id: "241128",
  },
  {
    name: "Togo",
    id: "241129",
  },
  {
    name: "Tonga",
    id: "241130",
  },
  {
    name: "Trinidad and Tobago",
    id: "241131",
  },
  {
    name: "Tunisia",
    id: "241132",
  },
  {
    name: "Turkey",
    id: "241133",
  },
  {
    name: "Turkmenistan",
    id: "241134",
  },
  {
    name: "Tuvalu",
    id: "241135",
  },
  {
    name: "Uganda",
    id: "241136",
  },
  {
    name: "Ukraine",
    id: "241137",
  },
  {
    name: "United Arab Emirates",
    id: "241138",
  },
  {
    name: "United Kingdom",
    id: "241139",
  },
  {
    name: "United States of America",
    id: "241140",
  },
  {
    name: "Uruguay",
    id: "241141",
  },
  {
    name: "Uzbekistan",
    id: "241142",
  },
  {
    name: "Vanuatu",
    id: "241143",
  },
  {
    name: "Venezuela",
    id: "241144",
  },
  {
    name: "Vietnam",
    id: "241145",
  },
  {
    name: "Yemen",
    id: "241146",
  },
  {
    name: "Zambia",
    id: "241147",
  },
  {
    name: "Zimbabwe",
    id: "241148",
  },
  {
    name: "Ivory Coast",
    id: "241367",
  },
]
