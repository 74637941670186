import { propertyRentRegex, propertySaleRegex } from "@lib/urls"
import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker"
export { wrapRootElement } from "./gatsby-shared"
CheckWebpFeature()

export const onRouteUpdate = ({ location }) => {
  const pathname = location.pathname
  const paths = pathname.split("/")

  const matchPaths = ["buy", "let", "property-for-sale", "property-for-rent"]
  const urlMap = {
    buy: "buy",
    let: "let",
    "property-for-sale": "buy",
    "property-for-rent": "let",
  }


  if(location.pathname.indexOf("team") > 0 && location.search.indexOf("category=Brokers") > 0){
    window.location.replace("/team/");
  }

  if (matchPaths.some((matchPath) => paths[1] === matchPath)) {
    if (paths[3] && !isNaN(paths[3])) {
      window.location.replace(`/${urlMap[paths[1]]}/${paths[2]}${paths[3]}/`)
    }
  }

  //  remove multiple slashes
  if (pathname.match(/\/{2,}/)) {
    window.location.replace(pathname.replace(/\/{2,}/g, "/"))
  }
}


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
      const currentPosition = getSavedScrollPosition(location)
      if(currentPosition?.length > 0 && currentPosition[1] > 0){
        setTimeout(() => {
          if (currentPosition[1] > 0) {
            window.scrollTo(0, currentPosition[1])
          }
        },1000)
        if (location.pathname.match(/buy\/properties-for-sale/)) {
          return false;
        }
        if (location.pathname.match(/let\/properties-for-rent/)) {
          return false;
        }
        if (location.pathname.match(/new-projects/)) {
          return false;
        }
      }
  return true;
};